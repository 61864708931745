<template>
  <div>
    <vue-breadcrumbs :breadCrumbs="breadCrumbs" />
    <div class="search_bar">
      <v-menu
        ref="menu"
        v-model="menu"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            v-model="filter.date"
            persistent-hint
            placeholder="Дата"
            prepend-innet-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
            readonly
            height="44"
            hide-details
            outlined
            dense
          ></v-text-field>
        </template>
        <v-date-picker
          v-model="filter.date"
          no-title
          @input="menu = false"
          :first-day-of-week="1"
        ></v-date-picker>
      </v-menu>
      <v-autocomplete
        v-model="filter.to_location"
        placeholder="Тур"
        item-text="name"
        item-value="id"
        :items="cities"
        height="44"
        hide-details
        outlined
        dense
      >
      </v-autocomplete>
      <v-btn height="44" width="160" color="primary" dense @click="search">
        Поиск
      </v-btn>
    </div>
    <today-conditions :info="info" />
    <v-data-table
      :headers="headers"
      :items="items.results"
      :loading="loading"
      disable-sort
      :options.sync="options"
      :server-items-length="items.count"
    >
      <template v-slot:[`item.bus_type`]="{ item }">
        {{ item.bus_type == "sitting" ? "Сидячий" : "Спальный" }}
      </template>
      <template v-slot:[`item.check_in`]="{ item }">
        {{ item.check_in | formatDate }}
      </template>
      <template v-slot:[`item.check_out`]="{ item }">
        {{ item.check_out | formatDate }}
      </template>
      <template v-slot:[`item.travel_time`]="{ item }">
        {{ item.travel_time | formatTime }}
      </template>
      <template v-slot:[`item.actions`]="{ item }">
        <vue-table-actions
          :item="item"
          @onEdit="onEdit(item)"
          @onDelete="onDelete(item)"
          :eyeEdit="true"
        />
      </template>
    </v-data-table>
  </div>
</template>

<script>
import vueBreadcrumbs from "@/components/ui/vueBreadcrumbs.vue";
import TodayConditions from "@/components/ui/todayConditions.vue";
import VueTableActions from "@/components/ui/vueTableActions.vue";
import { dynamicService } from "@/services/salesDynamic.service.js";
import { tourLocationService } from "@/services/locations.service.js";
import { citiesService } from "@/services/cities.service.js";
import { toursService } from "@/services/tours"
import moment from "moment";
export default {
  components: {
    vueBreadcrumbs,
    TodayConditions,
    VueTableActions,
  },
  data: () => ({
    items: [{}],
    options: {},
    headers: [
      { text: "Автобус", value: "number" },
      { text: "Тип", value: "bus_type" },
      { text: "Откуда", value: "from_location" },
      { text: "Тур", value: "to_location" },
      { text: "Отправление", value: "check_in" },
      { text: "Прибытие", value: "check_out" },
      { text: "Общее время", value: "travel_time" },
      { text: "Взрослый", value: "adult_price" },
      { text: "Детский", value: "child_price" },
      // { text: 'Пенсионер', value: 'pensioner_ticket' },
      { text: "Брони", value: "order_count" },
      { text: "", value: "actions" },
    ],
    loading: false,
    menu: false,
    info: {},
    filter: {},
    countTest: 0,
    breadCrumbs: [
      {
        text: "Динамика продаж блоков",
        to: "/administration/sales",
      },
      {
        text: "Туры",
        to: "/administration/sales/tours",
      },
    ],
    cities: [],
    cities_to: [],
  }),
  watch: {
    options: {
      handler() {
        this.getList();
        this.getStat()
        this.countTest++;
      },
      deep: true,
    },
  },
  created() {
    this.getCities();
  },
  filters: {
    formatDate(value) {
      return moment(value * 1000).format("DD.MM.YYYY hh:mm");
    },
    formatTime(value) {
      let hours = "", minutes = "";
      hours = parseInt(value / 3600);
      minutes = parseInt((value % 3600) / 60);
      if (hours == 1) hours = `1 час, `;
      else if (hours > 1 && hours < 5) hours = `${hours} часа, `;
      else hours = `${hours} часов, `;

      if (minutes == 1) minutes = `1 минута`;
      else minutes = `${minutes} минут`;
      return hours + minutes;
    },
  },
  methods: {
    search() {
      this.getList();
      this.getStat();
    },
    async getStat() {
      let res = await dynamicService.getToday(
        {
          from_date: this.filter.date || moment(new Date()).format('YYYY-MM-DD'),
          to_location: this.filter.to_location,
        },
        "tour"
      );
      this.info = res;
    },
    async getCities() {
      this.cities = await toursService.getAllTours();
    },
    async getList() {
      this.loading = true;
      let params = {
        page: this.options.page,
        size: this.options.itemsPerPage,
      };
      if(this.countTest > 1) {
        params.from_date = this.filter.date
        params.to_location = this.filter.to_location
      }
      const res = await dynamicService.getSalesDynamic(params, "tour");
      this.countTest++;
      this.items = res;
      this.loading = false;
    },
    onEdit(item) {
      let params = {
        bus_id: item.bus_id
      }
      if(this.filter.date) params.date = this.filter.date
      if(this.filter.to_location) params.to_location = this.filter.to_location
      this.$router.push({
        path: `/administration/sales/tours/${item.id}`,
        query: params
      });
    },
    async onDelete(item) {
      await dynamicService.deleteSalesDynamic(item.id, 'tour')
      this.$store.commit("ui/SET_NOTIFICATION", {
        show: true,
        message: "Успешно удалено!",
        color: "success",
      });
      this.getStat()
      this.getList()
    },
  },
};
</script>

<style lang="scss" scoped>
.search_bar {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 160px;
  align-items: center;
}
</style>
