var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('vue-breadcrumbs',{attrs:{"breadCrumbs":_vm.breadCrumbs}}),_c('div',{staticClass:"search_bar"},[_c('v-menu',{ref:"menu",attrs:{"close-on-content-click":false,"transition":"scale-transition","offset-y":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"persistent-hint":"","placeholder":"Дата","prepend-innet-icon":"mdi-calendar","readonly":"","height":"44","hide-details":"","outlined":"","dense":""},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.menu),callback:function ($$v) {_vm.menu=$$v},expression:"menu"}},[_c('v-date-picker',{attrs:{"no-title":"","first-day-of-week":1},on:{"input":function($event){_vm.menu = false}},model:{value:(_vm.filter.date),callback:function ($$v) {_vm.$set(_vm.filter, "date", $$v)},expression:"filter.date"}})],1),_c('v-autocomplete',{attrs:{"placeholder":"Тур","item-text":"name","item-value":"id","items":_vm.cities,"height":"44","hide-details":"","outlined":"","dense":""},model:{value:(_vm.filter.to_location),callback:function ($$v) {_vm.$set(_vm.filter, "to_location", $$v)},expression:"filter.to_location"}}),_c('v-btn',{attrs:{"height":"44","width":"160","color":"primary","dense":""},on:{"click":_vm.search}},[_vm._v(" Поиск ")])],1),_c('today-conditions',{attrs:{"info":_vm.info}}),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.items.results,"loading":_vm.loading,"disable-sort":"","options":_vm.options,"server-items-length":_vm.items.count},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.bus_type",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.bus_type == "sitting" ? "Сидячий" : "Спальный")+" ")]}},{key:"item.check_in",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.check_in))+" ")]}},{key:"item.check_out",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDate")(item.check_out))+" ")]}},{key:"item.travel_time",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatTime")(item.travel_time))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('vue-table-actions',{attrs:{"item":item,"eyeEdit":true},on:{"onEdit":function($event){return _vm.onEdit(item)},"onDelete":function($event){return _vm.onDelete(item)}}})]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }